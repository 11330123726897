#svg circle{
    fill:white;
    transition: 0.1s;

}
/*
#svg:hover circle{
    transition: 0.1s;

    fill:#8C9EFF;
}
#svg svg{
    transition: 0.1s;

}
#svg:hover svg{
    fill:white;
    transition: 0.1s;
}
*/

#test:hover svg, #test:hover circle{
    fill:white;
    transition: 0.1s;
}

#test svg, circle{
    fill:rgba(255,255,255,0.37);
    transition: 0.1s;
}

#test{
    border-radius:50%;
    border: solid 3px rgba(255,255,255,0.37);
    transition: 0.1s;
}
#test:hover{
    border: solid 3px white;
    transition: 0.1s;
}
