*{
    padding:0;
    margin: 0;
}

html{
    background-color: #101010;
    overflow-wrap: break-word;
}

#description a {
    color: rgb(255, 192, 192);
    text-decoration: none;
}

p{
    display: inline-block;
}

.App {
  color: white;
  font-family: "JetBrains Mono";
  background-color: #101010;

}

#cont{
    width:100%;
}

#title{
    font-size: 30px;
    font-weight: bold;

}
#subTitle{
    font-size: 25px;
    font-weight: 300;
    margin-top:8px
}

#leftFrame p{
    margin-bottom:0;
}

#description{
    font-size:20px;
    font-weight: 300;
    margin-top: 25px;
    color: rgba(255,255,255,0.37);
}
#leftFrame{
    display: flex;
    flex-direction: column;
}

#contacts{
    margin-top:25px;
    margin-top: 60px;

}

#secondCol{
    flex-direction: column;
    align-content: start;
}

/*supper small*/
@media only screen and (max-width: 768px){
    #leftFrame{
        margin-left: 25px;
    }
}
/*smaller screens*/
@media only screen and (max-width: 1200px){
    #firstCol{
        padding-top: 70px
    }
    #secondCol{
        padding-top: 50px;
        align-items: center;
    }
    
    #contacts > * {
        margin: 0 14px 0 14px;
    }

}

@media only screen and (min-width: 1200px) {
    #firstCol {
        height:100vh;
    }
    #cont{
        height:100vh;

    }
    
    #contacts > * {
        margin-right: 28px;
    }

    #secondCol{
        padding-top: 123px;
        overflow-y: auto;
        flex-flow: wrap;
        max-height: 100vh;
    }
    #secondCol::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0);
    }
    #secondCol:hover::-webkit-scrollbar-thumb{
        background-color: rgba(255,255,255,0.2);
        transition: 1s;
    }
    #secondCol::-webkit-scrollbar-thumb:hover {
        background: rgba(255,255,255,0.3);
    }
}



/* width */
::-webkit-scrollbar {
    width: 5px;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.2);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.3);
}