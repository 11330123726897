#cont{
    display:flex;
    flex-direction: column;
    padding: 34px 39px 0px 39px;
    background-color: #161616;
    max-width: 534px;
    width: 90vw;
    cursor: pointer;
    transition: 0.1s;
    transition-timing-function: ease-in;
}

#cont svg, #cont circle{
    fill: rgba(255,255,255,0.37);
    transition: 0.1s;
}

#cont:hover svg, #cont:hover circle{
    fill: white;
    transition: 0.1s;
}



#cont #GithubProject{
    border-radius:50%;
    border: solid 3px rgba(255,255,255,0.37);
    transition: 0.1s;
}

#cont:hover #GithubProject{
    border: solid 3px white;
    transition: 0.1s;
}



#cont:hover{
    transform: scale(1.1);
    transition-timing-function: ease-out;


}

hr{
    margin-bottom: 0
}

#cont p{
    margin-bottom: 0;
}

#title{
    font-size:20px;
    font-weight: bold;
}

#description{
    font-size: 15px;
    font-weight: 300;
    color: rgba(255,255,255,0.37);
    margin-top: 11px;
}

#foot{
    height: 83px;
    display: flex;
    align-items: center;
}

#link{
    font-size: 12px;
    font-weight: 300;
    color: rgba(255,255,255,0.37);
    margin-left: 14px
}